* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Nunito Sans", sans-serif;
    overflow-x: hidden;
    font-weight: 500;
    font-style: italic;
}

.custom-shape-divider-bottom-1647031027 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1647031027 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 46px;
}

.custom-shape-divider-bottom-1647031027 .shape-fill {
    fill: #fcfcfc;
}